<template>
    <div class="patient-financial-resume">
        <div class="patient-credit__resume">
      <div class="patient-credit__resume-item">
        <div>Crédito atual</div>
        <div class="credit">{{ getCreditFormatted(currentCredit)}}</div>
      </div>
      <div class="patient-credit__resume-item">
        <div>Depósitos</div>
        <div class="deposit">{{ deposits || '-' }}</div>
      </div>
      <div class="patient-credit__resume-item">
        <div>Saques</div>
        <div class="withdraw">{{ withdraws || '-' }}</div>
      </div>
      <div class="patient-credit__resume-item">
        <div>Pendências</div>
        <div class="pendencies">{{ pendencies || '-' }}</div>
      </div>
      <div class="patient-credit__resume-item">
        <div>Pagamentos</div>
        <div class="payments">{{ payments || '-' }}</div>
      </div>
    </div>
        
    </div>
</template>
<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'
export default {
    name: 'PatientFinancialResume',
    props: {
        currentCredit: Number,
        payments: Number,
        pendencies: Number,
        deposits: Number,
        withdraws: Number
    }  ,
    methods: {
      getCreditFormatted(amount) {
        if (!amount) return '-'
        return `R$ ${parseNumberToMoney(amount).trim().split('$')[1]}`
      },
    }  
}

</script>
<style lang="scss" scoped>
  .patient-credit__resume {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .patient-credit__resume-item {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;

      padding: 12px 12px;
      margin: 0 2px;

      border: 1px solid #d9dff2;
      border-radius: 8px;

      .credit {
        font-weight: 700;
        font-size: 24px;
        color: #305bf2;
      }

      .deposit {
        font-weight: 700;
        font-size: 24px;
        color: #0c1d59;
      }
      
      .withdraw {
        font-weight: 700;
        font-size: 24px;
        color: #0c1d59;
      }

      .pendencies {
        font-weight: 700;
        font-size: 24px;
        color: #f63220;
      }

      .payments {
        font-weight: 700;
        font-size: 24px;
        color: #525c7a;
      }
    }
  }
</style>